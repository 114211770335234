.category-card-item {
  display: flex;
  width: 301px;
  height: 401px;
  padding: 24px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  border-radius: 0px;
  border: 0px solid #fbc7ff;
  background-color: black;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 300px;
  position: relative;
  background-repeat: no-repeat;
  background-size: contain;
  flex-direction: column;

  .card-image {
    text-align: center;
    width: 80%;
  }

  .card-image img {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Ensure the image doesn't overflow */
    width: 300px; /* Set fixed width */
    height: 300px;
    margin: 0 auto; /* Center the card-image container */
    object-fit: scale-down;
  }

  .button-container {
    margin-top: 10px;
    width: 100%;
    height: 100%;
    text-align: center;
  }

  .card-title {
    overflow: hidden;
    color: #f579ff;
    text-overflow: ellipsis;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .card-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 11px;
    margin-bottom: 10px;

    .info-item {
      display: flex;
      flex-direction: row;
      width: 100%;
      font-size: 18px;
      font-weight: 500;

      .info-title {
        display: flex;
        flex: 1;
        color: #776a8c;
        font-family: Lalezar;
      }

      .info-desc {
        display: flex;
        flex: 1;
        color: white;
        font-family: Inter;
      }
    }
  }
}

@media screen and (max-width: 820px) {
  .category-list-container {
    padding-inline: 40px;
  }

  .category-button-groups {
    padding-inline: 40px;
    width: auto;
  }

  .category-card-item {
    width: 237px;
    padding: 12px;
  }

  .main-title-xl {
    font-size: 38px;
  }

  .category-list-area .category-list-title .title {
    font-size: 24px;
  }
}

@media screen and (max-width: 600px) {
  .category-list-container {
    padding-inline: 24px;
  }

  .category-button-groups {
    padding-inline: 40px;
    width: auto;
    flex-direction: column;
  }

  .category-card-item {
    width: 163px;
    padding: 12px 8px;
    height: auto;
    flex-basis: 163px;
  }

  .main-title-xl {
    font-size: 28px;
  }

  .slider-area {
    height: 292px;
    border-radius: 8px;
    margin-left: 16px;
    margin-right: 16px;
    width: calc(100% - 32px);
    margin-bottom: 24px;
  }

  .category-card-item .card-image {
    width: 65px;
    height: 65px;
    margin: 0 auto;

    img {
      height: inherit;
    }
  }

  .category-list-area .category-list-content {
    gap: 18px;
  }

  .category-card-item .card-title {
    font-size: 16px;
  }

  .category-card-item .card-info .info-item {
    font-size: 12px;
  }

  .category-list-area .category-list-title .title {
    font-size: 18px;
  }
}
