html {
  position: relative;
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #09070f;
  // background-image: url(../src/assets/images/Ellipse-bg.png);
  background-position: bottom;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: #fff;
  font-optical-sizing: auto;
  font-style: normal;

  &::before {
    position: absolute;
    content: "";
    width: 594px;
    height: 597px;
    border-radius: 597px;
    opacity: 0.78;
    background: rgba(214, 34, 218, 0.59);
    filter: blur(250px);
    top: -400px;
    left: 50%;
    margin-left: -255px;
  }

  &::after {
    position: absolute;
    content: "";
    width: 1324px;
    height: 1333px;
    border-radius: 1333px;
    opacity: 0.78;
    background: rgba(214, 34, 218, 0.59);
    filter: blur(250px);
    bottom: -1302px;
    left: 50%;
    margin-left: -530px;
  }
}

#root {
  height: 100%;
  position: relative;
  overflow: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main-area {
  padding-bottom: 100px;
}

.slider-area {
  width: 100%;
  height: 432px;
  margin-top: 50px;
  margin-bottom: 100px;
}

.main-title-xl {
  font-size: 67px;
  text-transform: capitalize;
  color: #fff;
  font-family: "Lalezar";
  text-align: center;
  margin-bottom: 20px;
}

.border-white-rad {
  border: 0.2px solid white;
  border-radius: 20px;
}

.main-title-m {
  font-size: 40px;
  text-transform: capitalize;
  color: #fff;
  font-family: "Lalezar";
  text-align: center;
  margin-bottom: 10px;
}

.category-button-groups {
  width: 854px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  gap: 24px;
  margin-bottom: 80px;

  .ant-select-arrow {
    color: #fff;
  }

  .category-button-item {
    display: flex;
    flex: 1;
  }
}

.creator-subtitle {
  margin-left: 45px;
}

.category-list-container {
  padding-inline: 86px;
  display: flex;
  flex-direction: column;
  gap: 72px;
}

.creator-head {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2.5rem;

  .creator-title {
    color: #fff;
    font-family: Lalezar;
    font-size: 4.188rem;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    /* 73.7px */
    text-transform: capitalize;
  }

  .creator-subtitle {
    color: #fff;
    font-family: Inter;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    margin-bottom: 15px;
    /* 54px */
  }
}

.btn-groups {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.creator-card {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: flex-end;

  .creator-left {
    background-image: url(../assets/images/angelimg.png);
    width: 487px;
    height: 487px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  .creator-right {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }
}

/* SignUpForm.css */
.search-results-container {
  max-height: 200px;
  /* Set a maximum height for the container */
  overflow-y: auto;
  /* Enable vertical scrolling */
}

.search-results {
  margin-top: 5px;
  /* Add some margin */
}

.search-result-item {
  cursor: pointer;
  /* Change cursor to pointer on hover */
  padding: 5px 0;
  /* Add some padding */
}

/* You can add more styles as needed */

.character-images {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  align-items: center;
  justify-content: center;
  justify-items: center;
  background-repeat: no-repeat;

  .characher-avatar {
    background: url("../assets/images/player-profile.png") lightgray -33px -26px /
      118.728% 126.523% no-repeat;
    width: 638px;
    height: 355px;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    background-color: #20192e;
    background-blend-mode: color-dodge;
    background-position: center;
  }

  .character-graph {
    background: url("../assets/images/player-graph.png");
    margin-left: 30px;
    background-size: cover;
    background-repeat: no-repeat;
    width: 638px;
    height: 355px;
  }
}

.tabPosition {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.stat-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid rgba(251, 199, 255, 0.4);
  width: 80%;
  align-items: center;
  justify-items: center;
  background: #20192e;
  margin-left: 10%;
  margin-top: 2%;
}

/* Style for each individual div */
.stat-box {
  width: calc(33.33% - 20px);
  /* Three boxes per row on desktop */
  margin: 10px;
  border: 1px solid rgba(251, 199, 255, 0.4);
  border-radius: 4px;
  background: #09070f;
  display: flex;
  width: 190px;
  gap: 24px;

  height: 100px;
  /* Set a fixed height for demo */

  .stat-box-card {
    display: flex;
    flex-direction: column;
    flex: 1 0 0;

    .stat-box-first-raw {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex: 0 0 auto;
      margin-left: 15px;

      .stat-box-first-raw-text {
        color: #8c93a3;
        margin-left: 5px;
        /* Mobile/Title 18px/Medium */
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
      }
    }

    .stat-box-footer-raw {
      font-family: Inter;
      margin-top: auto;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      color: #fff;
      margin-left: 15px;
      margin-bottom: 10px;
    }
  }
}

.customScroll::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
.customScroll::-webkit-scrollbar-track {
  background: #e0dfe6;
  border-radius: 4px;
}

/* Handle */
.customScroll::-webkit-scrollbar-thumb {
  background: #6e6b8c;
  border-radius: 4px;
}

/* Handle on hover */
.customScroll::-webkit-scrollbar-thumb:hover {
  background: #6e6b8c;
  border-radius: 4px;
}

@media only screen and (max-width: 1024px) {
  .stat-box {
    width: calc(33.33% - 20px);
    /* Three boxes per row on tablet */
  }
}

@media only screen and (max-width: 480px) {
  .stat-box {
    width: calc(50% - 20px);
    /* Two boxes per row on mobile */
  }
}

.creator-statistics-card {
  margin-top: 1.75rem;
  margin-left: 3.75rem;

  .creator-head-title {
    color: #fff;
    font-family: "Lalezar";
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
  }

  .creator-statistics-table-container {
    margin-top: 1.5rem;
    max-width: 1100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    .creator-statistics-table {
      background-image: url(../assets/images/line-bar-graph.png);
      width: 500px;
      height: 364px;
    }
  }
}

.creator-sponsors-card {
  margin-top: 1.75rem;
  margin-left: 3.75rem;

  .creator-head-title {
    color: #fff;
    font-family: "Lalezar";
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .creator-list-content {
    display: flex;
    align-items: flex-start;
    gap: 36px;
    overflow: auto;
    margin-bottom: 36px;
  }
}

.container {
  display: grid;
  background: #20192e;
  height: 300px;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
  gap: 10px;
}

@media screen and (max-width: 768px) {
  .container {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto auto;
    /* Define three rows for mobile */
  }

  .top-two {
    grid-row: 1 / 3;
    /* Span first two rows */
  }
}

@media screen and (max-width: 480px) {
  .container {
    grid-template-columns: 1fr;
    /* 1 column for mobile */
    grid-template-rows: auto auto auto;
    /* Define three rows for mobile */
  }
}

.item {
  background: #20192e;
  display: flex;
  justify-content: center;
  border-top-right-radius: 24px;
  width: 150px;
  align-items: center;

  .item-big-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    .align-items {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .person {
        height: 36px;
        width: 36px;
        background-image: url(../assets/images/user-circle.svg);
      }

      .three-person {
        height: 36px;
        width: 36px;
        background-image: url(../assets/images/users-three.svg);
      }

      .graph {
        height: 36px;
        width: 36px;
        background-image: url(../assets/images/graph.svg);
      }

      .circle {
        height: 36px;
        width: 36px;
        background-image: url(../assets/images/user-circle.svg);
      }

      .search {
        height: 36px;
        width: 36px;
        background-image: url(../assets/images/search-icon.svg);
      }

      .access {
        height: 36px;
        width: 36px;
        background-image: url(../assets/images/keyhole.svg);
      }
    }

    .three-column {
      color: #8c93a3;
    }

    .lock {
      height: 24px;
      width: 24px;
      background-image: url(../assets/images/lock-open.svg);
    }

    .score-text {
      color: #fff;
      font-family: Lalezar;
      font-size: 18px;
      font-weight: 600;
      font-style: normal;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .long {
      margin-left: 30px;
    }
  }
}

.green-soldier {
  border-radius: 6.771px;
  background: #322a41;
  background-image: url(../assets/images/greensoldier.png) !important;
  object-fit: contain;
  height: 184px;
  width: 100%;
  margin: 25px;
}

.category-list-area {
  .category-list-title {
    margin-bottom: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      color: #fff;
      font-size: 36px;
      font-weight: 500;
    }

    .btn-groups {
      display: flex;
      align-items: center;
      gap: 20px;

      .ant-btn {
        width: 42px;
        height: 42px;
        padding: 0;
      }
    }
  }

  .category-list-content {
    display: flex;
    align-items: flex-start;
    gap: 36px;
    overflow: auto;
    margin-bottom: 36px;
  }
}

.search-input {
  background: linear-gradient(270deg, #7d76a8 0%, #53386d 100%);
  border-radius: 8px;
  border: 1px solid var(--Solid-Colours-Brand-Primary-Primary-100, #feefff);
}

.creators {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-search {
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  padding: 5px 0px;
  align-items: flex-start;
  gap: 10px;
  width: 100%;

  input {
    width: 60%;
    background: transparent;
  }

  .ant-input-group-addon {
    button {
      height: 49px;
      width: 60px;
      background: transparent;
    }
  }
}

@media screen and (max-width: 1000px) {
  .slider-area {
    margin-top: 10px;
  }
}

@media screen and (max-width: 800px) {
  body {
    &::after {
      display: none;
    }

    &::before {
      display: none;
    }
  }

  .creator-head {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1.5rem;

    .creator-title {
      color: #fff;
      font-family: Lalezar;
      font-size: 2.375rem;
      font-style: normal;
      font-weight: 400;
      line-height: 110%;
      /* 73.7px */
      text-transform: capitalize;
    }

    .creator-subtitle {
      color: #fff;
      font-family: Inter;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      /* 54px */
    }
  }
}

@media screen and (max-width: 600px) {
  .slider-area {
    margin-top: 10px;
  }

  .creator-head {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1.5rem;

    .creator-title {
      color: #fff;
      font-family: Lalezar;
      font-size: 1.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 110%;
      /* 73.7px */
      text-transform: capitalize;
    }

    .creator-subtitle {
      color: #fff;
      font-family: Inter;
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      /* 54px */
    }
  }
}
