.header-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 64px;
  padding-top: 30px;
  padding-bottom: 30px;
  .header-logo-area {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
  .header-account-area {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: end;
  }
  .header-menu-area {
    display: flex;
    flex: 1;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      gap: 40px;

      li {
        a {
          color: #a4a9b6;
          font-size: 20px;
          font-weight: 700;
          text-decoration: none;
          cursor: pointer;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            border-radius: 4px;
            height: 2px;
            background: #ee14ff;
            width: 100%;
            left: 0;
            bottom: -6px;
            display: none;
          }

          &:hover {
            color: #fff;
          }

          &:hover::after {
            display: inline;
          }
        }
      }
    }
  }

  .dropdown-menu {
    color: #a4a9b6;
    background: rgb(29, 24, 56);
  }

  .active {
    color: #fff !important;
    content: "";
    width: 100%;
  }
  .bg-menu-button {
    display: none;
  }
}

.account-content {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 220px;

  img {
    width: 50px;
  }
}

@media screen and (max-width: 900px) {
  .header-area {
    padding-inline: 24px;

    .account-content {
      display: none;
    }
    .header-menu-area {
      display: none;
    }

    .header-logo-area {
      justify-content: flex-start;

      img {
        width: 60px;
        height: 32px;
      }
    }

    .bg-menu-button {
      display: inherit;
    }
  }
}
