.tgalogin-page-container {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px; 
    justify-content: center;
    padding-inline: 16px;
    padding-top: 45px;
    background-color: #0c0c0d; 
}

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f5f5f5;
}

.login-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; 
    max-width: 450px; 
    background-color: #1a1a1d; 
    padding: 20px;
    border-radius: 10px;
  }