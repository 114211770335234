.login-page-container {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 90px;
  justify-content: center;
  padding-inline: 16px;
  padding-top: 45px;

  &.sign-up-container {
    justify-content: flex-start;
    gap: 40px;
    padding-bottom: 500px;
  }

  .lg-title {
    font-size: 51px;
    font-style: normal;
    font-family: "Lalezar", system-ui;
    font-weight: 400;
  }

  .login-area {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .btn-groups {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 450px;
    max-width: 100%;
  }
}

.sign-form-area {
  padding-inline: 176px;
  display: flex;
  flex-direction: column;
  gap: 36px;
  width: calc(100% - 400px);

  .form-item-content {
    display: flex;
    flex-direction: column;

    gap: 36px;

    .input-area-multi {
      display: flex;
      flex-direction: column;
      gap: 36px;
      flex: 1;
    }

    .input-area {
      display: flex;
      flex-direction: column;
      gap: 8px;
      flex: 1;

      .input-title {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        color: #fff;
      }
    }
  }
}

.signup-btn:disabled,
.signup-btn[disabled] {
  color: white;
  background-color: gray;
}

.cs-avatar-img {
  height: 220px;
  width: 220px;
}

.cs-avatar-uploader {
  width: 100%;
  display: flex;
  height: 126px;
  border-radius: 6px;
  border: 1px dashed #b2b7c2;
  background: #1d1838;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
  justify-content: center;
  align-items: center;

  .uploader-title {
    font-weight: 600;
    color: #ee14ff;
    margin-bottom: 24px;
  }

  .upload-picture-btn {
    display: flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #f579ff;
    background: #ee14ff;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
    font-style: normal;
    font-weight: 600;
    color: #fff;
    gap: 4px;
  }
}

.form-item-content-btn {
  display: flex;
  padding-top: 100px;
  flex-direction: column;
  gap: 36px;
  justify-content: center;
  align-items: center;
}

.login-btn-area {
  font-size: 20px;
  color: #fff;

  span {
    color: #fff;
  }
}

.dots-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  .dots-item {
    width: 8px;
    height: 8px;
    background-color: #ee14ff;
    opacity: 0.24;
    border-radius: 50%;
    cursor: pointer;

    &.active {
      opacity: 1;
    }
  }
}

.back-btn {
  position: absolute;
  color: #fff;
  font-size: 24px;
  left: 20px;

  &:hover {
    color: #fff !important;
    opacity: 0.8;
  }
}

@media screen and (max-width: 1140px) {
  .sign-form-area {
    width: 100%;
  }
}

@media screen and (max-width: 720px) {
  .sign-form-area {
    .form-item-content {
      flex-direction: column;

      &.column-rezerve {
        flex-direction: column-reverse;
      }
    }
  }
}
