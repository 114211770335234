.checkout-form-area {
  padding-inline: 176px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 36px;
  width: calc(100% - 400px);

  .left-part {
    flex: 1;
    padding: 10px;
    box-sizing: border-box;
  }

  .right-part {
    flex: 1;
    padding: 10px;
    box-sizing: border-box;
  }
}

.signup-btn:disabled,
.signup-btn[disabled] {
  color: white;
  background-color: gray;
}

.color-picker-input {
  background: #06050c;
  color: #ffffff;
}

.ant-color-picker-trigger-text {
  color: #ffffff !important;
}

.color-picker-area {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.seperate-line {
  opacity: 0.4;
  background: #fbc7ff;
  width: 526px;
  height: 1px;
  margin-top: 120px;
}

.info-label {
  color: #fff;

  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  margin-top: 60px;
}

.input-area-multi {
  display: flex;
  flex-direction: column;
  gap: 36px;
  flex: 1;
}

.input-area {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 8px;
  flex: 1;

  .input-title {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    color: #fff;
  }
}

@media screen and (max-width: 1140px) {
  .checkout-form-area {
    width: 100%;
  }

  .info-label {
    margin-top: 10px;
  }
}

@media screen and (max-width: 720px) {
  .checkout-form-area {
    flex-direction: column;
    .form-item-content {
      flex-direction: column;

      &.column-rezerve {
        flex-direction: column-reverse;
      }
    }
  }
  .info-label {
    margin-top: 30px;
  }

  .seperate-line {
    margin-top: 30px;
  }
}
