.member-card {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid #fbc7ff;
  cursor: pointer;
  width: 226px;
  height: 300px;
  justify-content: space-around;
  align-items: center;

  img {
    height: 200px;
  }

  .member-info-main {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    background: #000;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    .member-title {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
    }

    .member-score {
      height: 40px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      color: var(--Solid-Colours-Brand-Dark-Gray-Dark-Gray-300, #5c657d);
      text-overflow: ellipsis;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
    }
  }
}

.member-card-item {
  height: 73%;
  width: 100%;
  border-radius: 12px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background: linear-gradient(
    181deg,
    rgba(57, 49, 106, 0) -31.67%,
    #050022 99.07%
  );
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  background-repeat: no-repeat;
  background-size: contain;
  flex-direction: column;

  &.purple {
    background-image: url(../../assets/images/angel.svg);
  }
  &.gold {
    background-image: url(../../assets/images/beeswarm.svg);
  }
  &.pink {
    background-image: url(../../assets/images/biggamepets.svg);
  }
  &.red {
    background-image: url(../../assets/images/biggamepetssv.svg);
  }
  &.white {
    background-image: url(../../assets/images/angel.svg);
  }
}

@media screen and (max-width: 820px) {
  .category-list-container {
    padding-inline: 40px;
  }
  .category-button-groups {
    padding-inline: 40px;
    width: auto;
  }
  .category-card-item {
    width: 237px;
    padding: 12px;
  }

  .main-title-xl {
    font-size: 38px;
  }
  .category-list-area .category-list-title .title {
    font-size: 24px;
  }
}

@media screen and (max-width: 600px) {
  .member-card {
    .member-card-item {
      height: 65%;
    }
    .member-title {
      height: 20px !important;
      font-size: 10px !important;
    }

    .member-score {
      height: 20px !important;
      font-size: 10px !important;
    }
  }
}
