.category-card-item {
  display: flex;
  width: 301px;
  height: 401px;
  padding: 24px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  border-radius: 12px;
  background: linear-gradient(
    181deg,
    rgba(57, 49, 106, 0) -31.67%,
    #050022 99.07%
  );
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 300px;
  position: relative;
  background-repeat: no-repeat;
  background-size: contain;
  flex-direction: column;

  &.purple {
    background-image: url(../../assets/images/card-purple.svg);
  }

  &.gold {
    background-image: url(../../assets/images/card-gold.svg);
  }

  &.pink {
    background-image: url(../../assets/images/card-pink.svg);
  }

  &.red {
    background-image: url(../../assets/images/card-red.svg);
  }

  &.white {
    background-image: url(../../assets/images/card-white.svg);
  }

  .card-image {
    text-align: center;
    width: 100%;
  }

  .card-title {
    overflow: hidden;
    color: #f579ff;
    text-overflow: ellipsis;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .card-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 11px;
    margin-bottom: 10px;

    .info-item {
      display: flex;
      flex-direction: row;
      width: 100%;
      font-size: 18px;
      font-weight: 500;
      margin-left: 15px;

      .info-title {
        display: flex;
        flex: 1;
        color: #776a8c;
        font-family: Lalezar;
      }

      .info-desc {
        display: flex;
        flex: 1;
        margin-right: 35px;
        font-family: Inter;
        font-size: 15px;
      }
    }
  }
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
  z-index: 9999;
  /* Ensure modal is on top of other elements */
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  border-radius: 12px;
  border: none;
  /* Remove border from modal content */
  width: 250px;
  height: 401px;
  position: relative;
  z-index: 10000;
}

.modal-content .card-info {
  background: #06050c;
  height: 151px;
  width: 100%;
  border-radius: 12px;
  /* Ensure child elements inherit border-radius */
}

.modal-content .card-info .card-text {
  color: white;
}

.modal-content .card-toggle-button-collection .card-toggle-button {
  color: #5c657d;
}

@media screen and (max-width: 820px) {
  .category-list-container {
    padding-inline: 40px;
  }

  .category-button-groups {
    padding-inline: 40px;
    width: auto;
  }

  .category-card-item {
    width: 237px;
    padding: 12px;
  }

  .main-title-xl {
    font-size: 38px;
  }

  .category-list-area .category-list-title .title {
    font-size: 24px;
  }
}

@media screen and (max-width: 600px) {
  .category-list-container {
    padding-inline: 24px;
  }

  .category-button-groups {
    padding-inline: 40px;
    width: auto;
    flex-direction: column;
  }

  .category-card-item {
    width: 163px;
    padding: 12px 8px;
    height: auto;
    flex-basis: 163px;
  }

  .main-title-xl {
    font-size: 28px;
  }

  .slider-area {
    height: 292px;
    border-radius: 8px;
    margin-left: 16px;
    margin-right: 16px;
    width: calc(100% - 32px);
    margin-bottom: 24px;
  }

  .category-card-item .card-image {
    width: 65px;
    height: 65px;
    margin: 0 auto;

    img {
      height: inherit;
    }
  }

  .category-list-area .category-list-content {
    gap: 18px;
  }

  .category-card-item .card-title {
    font-size: 16px;
  }

  .category-card-item .card-info .info-item {
    font-size: 12px;
  }

  .category-list-area .category-list-title .title {
    font-size: 18px;
  }
}
